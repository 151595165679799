<template lang="pug">
include ../../../configs/template
div.row.w-100.text-left
  div.col-sm-12.col-md-6
    +data-info('number', 'sailorDocument.name_book')
  div.col-sm-12.col-md-6
    +data-info('strictBlank', 'sailorDocument.blank_strict_report')
  div.col-12(v-if="sailorDocument.auth_agent_ukr")
    +data-info('agent', 'sailorDocument.auth_agent_ukr')
  div.col-12(v-if="sailorDocument.auth_agent_eng")
    +data-info('agentNameEng', 'sailorDocument.auth_agent_eng')
  div(v-if="sailorDocument.waybill_number").col-12
    +data-info('wayBillNumber', 'sailorDocument.waybill_number')
  div.col-sm-12.col-md-6
    +data-info('dateIssue', 'getDateFormat(sailorDocument.date_issued)')
  div.col-sm-12.col-md-6(v-if="sailorDocument.branch_office")
    +data-info('affiliate', 'affiliateByID(sailorDocument.branch_office)[nameLang]')
  div.col-sm-12.col-md-6(v-else)
    +data-info('affiliate', 'sailorDocument.other_branch_office')
  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12.col-md-6
    +data-info('recordAuthor', 'sailorDocument.created_by.name')
  div(v-if="checkAccess('document-author-view') && sailorDocument.created_by").col-sm-12.col-md-6
    +data-info('createDate', 'sailorDocument.created_by.date')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").col-sm-12.col-md-6
    +data-info('verifier', 'sailorDocument.verificated_by.name')
  div(v-if="checkAccess('verification-author-view') && sailorDocument.verificated_by").col-sm-12.col-md-6
    +data-info('verificationDate', 'sailorDocument.verificated_by.date')
  div.col-12
    +data-info('status', 'statusById(sailorDocument.status_document)[nameLang]')(:class="getStatus(sailorDocument.status_document)")
  div(v-if="sailorDocument.comments?.length").col-sm-12
    h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
    v-divider
    div(v-for="(comment, index) in sailorDocument.comments" :key="comment.id").ml-4
      div
        +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
      div(v-if="checkAccess('backOffice')").pt-2.py-3
        +data-info('createdBy', 'comment.created_by.name')
      div.pt-2.py-3
        +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
      div.pt-2.py-3
        +data-info('reasonComment', 'comment.comment')
      v-divider(v-if="index !== sailorDocument.comments.length-1")

  div.col-12.mt-3
    v-btn(
      v-if="checkAccess('downloadFile')"
      @click="saveDocument"
      :loading="isLoadDocumentSaving"
      color="primary").mr-3 {{ $t('saveDoc') }}
    v-btn(
      v-if="!sailorDocument.blank_strict_report"
      @click="showStatementSaving = !showStatementSaving"
      color="primary") {{ $t('saveStatement') }}
  div(v-if="showStatementSaving").col-12.d-flex.align-start
    +field('strictBlank', '"strictBlank"')(type="number")
    v-btn(
      @click="saveApplicationDocument"
      :loading="isLoadStatementSaving"
      color="primary").ml-3 {{ $t('saveStatement') }}

  div(v-if="checkAccess('serviceRecordBook', 'viewRecord', sailorDocument)").col-12.px-0
    SailorRecordBookLine(:serviceRecordBookId="sailorDocument.id").col-12.px-0
</template>

<script>
import { getStatus, getDateFormat } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  props: {
    comments: { type: Array, default: () => ([]) },
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    SailorRecordBookLine: () => import('./SailorRecordBookLine/Table.vue')
  },
  data () {
    return {
      strictBlank: null,
      isLoadDocumentSaving: false,
      isLoadStatementSaving: false,
      showStatementSaving: false,
      getStatus,
      checkAccess,
      getDateFormat,
      rejectStatusId: 13
    }
  },
  computed: {
    ...mapGetters(['affiliateByID', 'statusById', 'rejectionReasonByID', 'getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    verificationRefusalReason () {
      return this.comments.find(item => item.additional_info.cancel_reason || item.comment)
    },
    verificationRefusalReasonName () {
      return this.rejectionReasonByID(this.verificationRefusalReason.additional_info.reason)[this.nameLang]
    }
  },
  methods: {
    ...mapActions(['getRecordBookByID', 'getStatementDocumentSRB', 'generateDccumentSRB']),

    saveDocument () {
      this.isLoadDocumentSaving = true
      this.generateDccumentSRB({
        ...this.$route.params,
        nameBook: this.sailorDocument.name_book
      })
      this.isLoadDocumentSaving = false
    },

    /** Save service record book statement */
    async saveApplicationDocument () {
      this.isLoadStatementSaving = true
      this.showStatementSaving = true
      await this.getStatementDocumentSRB({
        body: { service_record: this.sailorDocument.id, num_blank: this.strictBlank },
        nameBook: this.sailorDocument.name_book,
        type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
      await this.getRecordBookByID({ ...this.$route.params })
      this.showStatementSaving = false
      this.isLoadStatementSaving = false
    }
  }
}
</script>
